<template>
  <main class="ml-12">
    <trac-loading v-if="isLoading" />
    <trac-validation-observer slim v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(sendProductAdjustment)">
        <div class="wrapper">
          <trac-back-button> Back </trac-back-button>
          <div class="flex flex-row justify-between items-end">
            <ul class="flex-col mt-12 mb-8">
              <li><a href="#">Inventory Control</a></li>
              <li class="font-bold mt-4 text-2xl">Add Stock</li>
            </ul>
            <div class="mb-8">
              <trac-button buttonType="submit" class="uppercase"
                >Confirm</trac-button
              >
            </div>
          </div>
        </div>
        <div class="px-8 py-8 bg-white rounded-lg big-shadow">
          <span class="uppercase text-sm font-semibold">Item</span>
          <div class="mt-8">
            <div
              v-for="(item, i) in productsToAdjust"
              :key="i"
              class="items-center"
            >
              <div class="grid grid-cols-3 gap-10 mt-2">
                <div>
                  <span class="text-sm font-bold capitalize">{{
                    item.title
                  }}</span>
                </div>
                <div class="">
                  <trac-validation-provider
                    :name="item.title + ' quantity'"
                    rules="required|integer|positive"
                    v-slot="{ errors }"
                  >
                    <trac-input
                      class=""
                      v-model.number="item.stock"
                      placeholder="Enter No. of Units"
                    ></trac-input>
                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>
                </div>
                <div class="">
                  <trac-validation-provider
                    :name="item.title + ' reason'"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <trac-input
                      class="w-full"
                      v-model="item.update_reason"
                      title="Reason for update"
                      placeholder="Enter reason"
                    />
                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <trac-modal v-if="newReasonModal" @close="newReasonModal = false">
          <EnterReason></EnterReason>
        </trac-modal>
      </form>
    </trac-validation-observer>
  </main>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
} from "../../../browser-db-config/localStorage";
import EnterReason from "./EnterReason";
import { eventBus } from "./../../../main";

export default {
  name: "AddStockNext",
  components: { EnterReason },
  data() {
    return {
      isLoading: false,
      addOptions: [
        { name: "Returned Restock" },
        { name: "Stock Received" },
        { name: "Unexplained" },
      ],
      removeOptions: [
        { name: "Theft" },
        { name: "Damaged" },
        { name: "Unexplained" },
      ],
      selectedStore: null,
      productsToAdjust: null,
      newReasonModal: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (!GET_LOCAL_DB_DATA("stock-adjustment-store-detail")) {
      next({ name: "AddStock" });
    } else {
      next();
    }
  },
  created() {
    this.selectedStore = GET_LOCAL_DB_DATA("stock-adjustment-store-detail");

    this.productsToAdjust = GET_LOCAL_DB_DATA("products-to-adjust").map(
      (product) => {
        return {
          ...product,
          store_stock: product.store_stock,
          title: product.title,
          _id: product._id,
          business_id: GET_USER_BUSINESS_ID(),
          stock: "",
          store_id: this.selectedStore._id,
          update_reason: "",
          variant_id: product.is_local_variant ? product._id : "",
          variation: product.is_local_variant || false,
        };
      }
    );
  },
  methods: {
    selectReason(reason) {
      // (reason);
    },
    selectedStoreStock(product) {
      return (
        (product.store_stock || []).find(
          (ss) => ss.store_id === this.selectedStore._id
        ) || {}
      );
    },
    optionSelected() {
      this.newReasonModal = true;
    },
    async sendProductAdjustment() {
      const payload = this.productsToAdjust.map((product) => {
        // delete product.title;
        // product =
        //   { ...product, stock: (product.stock === "" ? 0 : product.stock) +
        //   (this.selectedStoreStock(product).stock || 0)};

        return {
          id: product.id,
          business_id: product.business_id,
          stock_quantity: product.stock,
          store_id: product.store_id,
          is_variant: product.is_local_variant,
          reason: product.update_reason,
          // variant_id: product.variant_id,
        };
      });

      this.isLoading = true;

      // Send data
      const res = await this.$store.dispatch("STOCK_UPDATES_V2", payload);

      if (res.status) {
        this.$store.dispatch("REFRESH_ALL_PRODUCTS", payload);
        this.$router.replace({ name: "StockAdjustment" });
      } else {
        // alert(res.message || res.error);
        eventBus.$emit("trac-alert", { message: res.message || res.error });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
